import { Flex } from "../roomkit/TileMenu/StyledMenuTile";
import { Audio } from "react-loader-spinner";

const FullPageProgress = () => (
  <Flex css={{ size: "100%" }}>
    <Audio
      height="100"
      width="100"
      color="#F5C950"
      ariaLabel="audio-loading"
      wrapperStyle={{}}
      wrapperClass="wrapper-class"
      visible={true}
    />
  </Flex>
);

export default FullPageProgress;
