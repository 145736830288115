import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";

import {
  HMSRoomProvider,
  selectLocalPeerID,
  useHMSStore,
} from "@100mslive/react-sdk";
import { ThemeTypes } from "./roomkit/Theme/ThemeProvider";
import { Box } from "./roomkit/Layout/Box";
import { AppContextProvider } from "./components/context/AppContext";
import { FeatureFlags } from "./services/FeatureFlags";
import { FingerprintProvider } from "./components/Fingerprint/fingerprint";
import {
  getUserToken as defaultGetUserToken,
  getBackendEndpoint,
} from "./services/tokenService";
import "./base.css";
import "./index.css";
// @ts-ignore
import Logo from "./images/logo_no_tagline_1375_475.png";
import FullPageProgress from "./components/FullPageProgress";
import { KeyboardHandler } from "./components/Input/KeyboardInputManager";
import PostLeave from "./components/PostLeave";
import { AppData } from "./components/AppData/AppData";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { HMSThemeProvider } from "./roomkit/Theme";
import { AppRoutesProps } from "./@types";
import BBPreviewScreen from "./components/BBPreviewScreen";

const Conference = React.lazy(() => import("./components/conference"));
const PreviewScreen = React.lazy(() => import("./components/PreviewScreen"));
const ErrorPage = React.lazy(() => import("./components/ErrorPage"));

const defaultTokenEndpoint = process.env
  .REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
  ? `${getBackendEndpoint()}${
      process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
    }/`
  : process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT || "";

const envPolicyConfig = JSON.parse(process.env.REACT_APP_POLICY_CONFIG || "{}");
const bthsRoomId = "67277296849b17dcfc918cc3";

let roomName = "";
if (window.location.host.includes("localhost")) {
  roomName = window.location.pathname.split("/")[1].split("-")[0] || "Mike";
} else {
  roomName = window.location.pathname.split("/")[1].split("-")[0] || "Mike";
}

roomName =
  roomName === "leave"
    ? window.location.pathname.split("/")[2] || "Mike"
    : roomName;

document.title = `${roomName}'s ${document.title}`;

const getAspectRatio = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => {
  const host = process.env.REACT_APP_HOST_NAME || window.location.hostname;
  const portraitDomains = (
    process.env.REACT_APP_PORTRAIT_MODE_DOMAINS || ""
  ).split(",");
  if (portraitDomains.includes(host) && width > height) {
    return { width: height, height: width };
  }
  return { width, height };
};

export function EdtechComponent({
  roomId = "",
  tokenEndpoint = defaultTokenEndpoint,
  themeConfig: {
    aspectRatio = "1-1",
    font = "Arial",
    color = "#F5C950",
    theme = ThemeTypes.default,
    logo = "",
    headerPresent = "false",
    metadata = "",
  },
  getUserToken = defaultGetUserToken,
  policyConfig = envPolicyConfig,
}) {
  const { 0: width, 1: height } = aspectRatio
    .split("-")
    .map((el: string) => parseInt(el));
  const [themeType, setThemeType] = useState(theme);
  const [urls, setUrls] = useState<SubwireRooms>();
  interface SubwireRooms {
    [name: string]: {
      roomId: string;
      venmo?: string;
      fingerprint?: string;
      created_at?: number;
    };
  }

  useEffect(() => {
    console.log({urls})
  }, [urls])

  useEffect(() => {
    setThemeType(theme);
  }, [theme]);

  return (
    <ErrorBoundary>
      <FingerprintProvider>
        <HMSRoomProvider isHMSStatsOn={FeatureFlags.enableStatsForNerds}>
          <HMSThemeProvider
            themeType={"dark"}
            aspectRatio={getAspectRatio({ width, height })}
            theme={{
              colors: {
                // @ts-expect-error
                brandLight: "#e8c972",
                brandDefault: "#F5C950",
                brandDark: "#90752d",
                brandDisabled: "#90752d",
              },
              fonts: {
                // @ts-expect-error
                sans: ["$sans", "Inter", "sans-serif"],
              },
            }}
          >
            <AppContextProvider
              roomId={roomId}
              tokenEndpoint={tokenEndpoint}
              policyConfig={policyConfig}
              appDetails={metadata}
              logo={logo || Logo}
            >
              <Box
                css={{
                  w: "100%",
                  ...(headerPresent === "true"
                    ? { flex: "1 1 0", minHeight: 0 }
                    : { h: "100%" }),
                }}
              >
                  <AppRoutes
                    getUserToken={getUserToken}
                    appDetails={metadata}
                    urls={urls}
                  />
              </Box>
            </AppContextProvider>
          </HMSThemeProvider>
        </HMSRoomProvider>
      </FingerprintProvider>
    </ErrorBoundary>
  );
}

const defaultRole = "viewer";
const defaultVenmo = "subwireceo";

function AppRoutes({ getUserToken, appDetails, urls }: AppRoutesProps) {
  const localPeerID = useHMSStore(selectLocalPeerID);
  const { roomId } = useParams();

  return (
    <Router>
      <AppData appDetails={appDetails} />
      <KeyboardHandler />
      <Routes>
        <Route path="/test" element={<Box>TEST</Box>} />
        <Route
          path="/lsjba"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="viewer"
                roomName="LSJBA"
              />
            </Suspense>
          }
        />
        <Route
          path="/lsjba-on-stage"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="admin"
                roomName="LSJBA"
              />
            </Suspense>
          }
        />
        <Route
          path={`/lsjba/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"viewer"}
                venmoHandle={"subwireceo"}
                vanityUrl={"LSJBA"}
              />
            </Suspense>
          }
        />
        <Route
          path={`/lsjba-on-stage/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"admin"}
                venmoHandle={"subwireceo"}
                vanityUrl={"LSJBA"}
              />
            </Suspense>
          }
        />
        <Route
          path="/bths-on-stage"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="stage"
                roomName="BTHS"
              />
            </Suspense>
          }
        />
        <Route
          path="/sghs-on-stage"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="stage"
                roomName="SGHS"
              />
            </Suspense>
          }
        />
        <Route
          path="/miketerry-on-stage"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="admin"
                roomName="SGHS"
              />
            </Suspense>
          }
        />
        <Route
          path="/miketerry"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="viewer"
                roomName="SGHS"
              />
            </Suspense>
          }
        />
        <Route
          path="/bths-admin"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="admin"
                roomName="BTHS"
              />
            </Suspense>
          }
        />
        <Route
          path="/sghs-admin"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="admin"
                roomName="SGHS"
              />
            </Suspense>
          }
        />
        <Route
          path="/bths"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="viewer"
                roomName="BTHS"
              />
            </Suspense>
          }
        />
        <Route
          path="/sghs"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <BBPreviewScreen
                getUserToken={getUserToken}
                room={bthsRoomId}
                role="viewer"
                roomName="SGHS"
              />
            </Suspense>
          }
        />

        <Route
          path={`/bths/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"viewer"}
                venmoHandle={"subwireceo"}
                vanityUrl={"BTHS"}
              />
            </Suspense>
          }
        />
        <Route
          path={`/sghs/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"viewer"}
                venmoHandle={"subwireceo"}
                vanityUrl={"SGHS"}
              />
            </Suspense>
          }
        />
        <Route
          path={`/miketerry-on-stage/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"admin"}
                venmoHandle={"subwireceo"}
                vanityUrl={"SGHS"}
              />
            </Suspense>
          }
        />
        <Route
          path={`/bths-on-stage/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"stage"}
                venmoHandle={"subwireceo"}
                vanityUrl={"BTHS"}
              />
            </Suspense>
          }
        />
        <Route
          path={`/sghs-on-stage/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"stage"}
                venmoHandle={"subwireceo"}
                vanityUrl={"SGHS"}
              />
            </Suspense>
          }
        />
        <Route
          path={`/bths-reduced/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"stage-reduced"}
                venmoHandle={"subwireceo"}
                vanityUrl={"BTHS"}
              />
            </Suspense>
          }
        />
        <Route
          path={`/bths-admin/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"admin"}
                venmoHandle={"subwireceo"}
                vanityUrl={"BTHS"}
              />
            </Suspense>
          }
        />
        <Route
          path={`/sghs-admin/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"admin"}
                venmoHandle={"subwireceo"}
                vanityUrl={"SGHS"}
              />
            </Suspense>
          }
        />
        <Route
          path={`/miketerry/live`}
          element={
            <Suspense fallback={<FullPageProgress />}>
              <Conference
                room={bthsRoomId}
                userRole={"viewer"}
                venmoHandle={"subwireceo"}
                vanityUrl={"BTHS"}
              />
            </Suspense>
          }
        />
        <Route
          path="/leave/*"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <PostLeave vanityUrl={roomName} />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<FullPageProgress />}>
              <ErrorPage error="Invalid URL!" />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

export default function App() {
  return (
    <HMSRoomProvider isHMSStatsOn={FeatureFlags.enableStatsForNerds}>
      {" "}
      <EdtechComponent
        themeConfig={{
          aspectRatio: process.env.REACT_APP_TILE_SHAPE,
          theme:
            process.env.REACT_APP_THEME === "light"
              ? ThemeTypes.default
              : ThemeTypes.default,
          color: process.env.REACT_APP_COLOR,
          logo: process.env.REACT_APP_LOGO,
          font: process.env.REACT_APP_FONT,
          headerPresent: process.env.REACT_APP_HEADER_PRESENT,
          metadata: process.env.REACT_APP_DEFAULT_APP_DETAILS, // A stringified object in env
        }}
        getUserToken={defaultGetUserToken}
      />
    </HMSRoomProvider>
  );
}
